// https://github.com/twbs/bootstrap/blob/master/less/type.less
@import (reference) '~bootstrap/less/type.less';

// override font-weights for header tags
h1,
.h1 {
  // Todo: use Zola-UI variables once its added as part of the style refresh
  font-family: @font-family-serif;
  font-size: 32px;
  font-weight: @font-weight-medium;
  color: @gray-base;
}

h2,
h3,
h4,
h5,
.h2,
.h3,
.h4,
.h5 {
  .regular();
}

h5,
.h5 {
  color: @gray-base;
}

h6,
.h6 {
  letter-spacing: @letter-spacing-lg;
  .bold();
  color: @gray-base;
}

// override our paragraphs
p {
  color: @gray-dark;
  @media (min-width: @screen-lg-min) {
    font-size: @font-size-large;
  }
}

.text-black {
  color: @black;
}

.text-red {
  color: @red;
}

// on smaller screens, shrink the header sizes
@media (max-width: @screen-md-max) {
  h1,
  .h1 {
    font-size: @font-size-h1-small;
  }
  h2,
  .h2 {
    font-size: @font-size-h2-small;
  }
  h3,
  .h3 {
    font-size: @font-size-h3-small;
  }
}
@media (max-width: @screen-xs-max) {
  h4,
  .h4 {
    font-size: @font-size-h4-small;
  }
  h5,
  .h5 {
    font-size: @font-size-h5-small;
  }
  h6,
  .h6 {
    font-size: @font-size-h6-small;
  }
}

// Remove most of the blockquote formatting
blockquote {
  padding: 10px 0;
  border-left: none;
  footer:before {
    content: '';
  }
  font-size: @font-size-base;
  @media (min-width: @screen-sm-min) {
    font-size: @blockquote-font-size;
  }
  @media (min-width: @screen-md-min) {
    font-size: 20px;
    p {
      font-size: inherit;
    }
  }
  @media (min-width: @screen-lg-min) {
    font-size: 24px;
  }
}

// override small body copy to get it to be exactly 12px
// unsure why bootstrap was rounding this off
small,
.small {
  font-size: percentage(@font-size-small / @font-size-base);
}

.font-family-sans-serif {
  font-family: @font-family-sans-serif;
}
.font-family-serif {
  font-family: @font-family-serif;
}

// Easily add this font weight since it's used more than bold
.semibold {
  font-weight: 600;
}

// override lead text
.lead {
  line-height: inherit;
  font-size: @font-size-large;
  @media (min-width: @screen-lg-min) {
    font-size: @font-size-larger;
  }
}

.header {
  position: relative;
  padding-bottom: 9px;
  margin-bottom: 20px;
  // add a minimal bottom border
  &:after {
    content: '';
    background-color: @gray-border;
    display: block;
    height: 1px;
    width: 50px;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -25px;
    .header-left& {
      left: 0;
      margin-left: 0;
    }
  }
  // if we have a subtitle in the header, style it a certain way
  .subtitle {
    text-transform: uppercase;
    color: @gray-dark;
    margin-bottom: -(@line-height-computed / 2); // invert the bottom margin to pull the main title closer
  }
}

// add some text-coloring additional helpers
.text-secondary {
  .text-emphasis-variant(@gray-dark);
}
.text-brand-alternate {
  .text-emphasis-variant(@brand-alternate);
}
.text-brand-dark {
  .text-emphasis-variant(@brand-dark);
}
.text-holiday {
  .text-emphasis-variant(@red);
  a:hover & {
    color: darken(@red, 10%);
  }
}
.text-success {
  .text-emphasis-variant(@brand-success);
}
.bv-cv2-cleanslate div[class*='bv-core-container-'] .bv-action-bar-header {
  font-family: @font-family-serif !important;
  font-weight: @font-weight-medium !important;
}
.onboard-container__container {
  h1 {
    font-family: @font-family-serif;
    font-weight: @font-weight-medium;

    span {
      font-family: @font-family-serif;
      font-weight: @font-weight-medium;
    }
  }
}
